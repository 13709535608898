import React, { useState, useEffect, useRef } from "react";
import { Transition } from 'react-transition-group';
import FixedImage from "../fixedImage";

import * as styleSheet from "./index.module.scss"


const SidebarReviewRotator = ({ reviews, redStar }) => {
	const [ reviewIndexMax ] = useState(reviews.length - 1);
	const [ currentReview, setCurrentReview ] = useState(0);
	const [ reviewHeight, setReviewHeight ] = useState(0);
	const firstReview = useRef();

	useEffect( () => {
		const rotation = setInterval(() => { 
			setCurrentReview(currentReview < reviewIndexMax ? currentReview + 1 : 0);
		}, 8000);

		return () => {
			clearInterval(rotation)
		}
	}, [currentReview, setCurrentReview, reviewIndexMax]);

	useEffect( () => {
		if( firstReview && firstReview.current ){
			setReviewHeight(firstReview.current.offsetHeight);
		}
		
	}, []);

	const reviewTransitionStyles = {
		maxHeight: reviewHeight === 0 ? 'initial': reviewHeight + "px",
		transition: 'max-height 0.6s ease-in-out',
		position: 'relative',
		height: "750px",
		overflow: "hidden"
 	};

	 const reviewContainerTransitionStyles = {
		entering: { 
			opacity: 0,
			position: "absolute",
			top: "0",
			left: "0"
		},
		entered:  { 
			opacity: 1,
			position: "absolute",
			top: "0",
			left: "0"
		},
		exiting:  { 
			opacity: 0,
			position: "absolute",
			top: "0",
			left: "0"
		},
		exited:  { 
			opacity: 0,
			position: "absolute",
			top: "0",
			left: "0"
		},
 	};

	const reviewEnter = (node, isAppearing) => {
		setReviewHeight(node.offsetHeight);
	};


	return (
		<div className={styleSheet.SidebarReviewRotator}>
			<div style={reviewTransitionStyles}>
				{ reviews && reviews.map( (review, index) => {
					return (
						<Transition key={review.id} in={currentReview === index} timeout={250} mountOnEnter unmountOnExit onEnter={(node,isAppearing)=>{ reviewEnter(node, isAppearing) }}>
							{(state) => (
								<div className={styleSheet.review} style={{...reviewContainerTransitionStyles[state]}} ref={index === 0 && firstReview}>
									<>
										<div className={styleSheet.rating}>
											{ [...Array(review.ACFReview.stars)].map((_val, index)=>{
												return <FixedImage key={`${review.id}star-${index}`} image={redStar} passedClass={styleSheet.star} />;
											})}
										</div>
										{ review.ACFReview.blurb && <div className={styleSheet.blurb}>
											{ review.ACFReview.blurb && (
												<>
													<div className={styleSheet.content} dangerouslySetInnerHTML={{__html:review.ACFReview.blurb}}></div>
												</>
											)}
										</div> }
										{ review.title && <span className={styleSheet.name} dangerouslySetInnerHTML={{__html: review.title}}></span> }
										{ review.ACFReview.location && <span className={styleSheet.location} dangerouslySetInnerHTML={{__html: review.ACFReview.location}}></span> }
									</>
								</div>
							)}
						</Transition>
					)
				})}
			</div>
		</div>
	)
}

export default SidebarReviewRotator;