import React from "react";
import { Link } from "gatsby";
import FixedImage from "../fixedImage"
import { GatsbyImage } from "gatsby-plugin-image";

import * as styleSheet from "./index.module.scss"

const Dentist = ({ dentist, passedClass = styleSheet.normal, icon = null }) => {
	
	console.log(dentist);

	const {
		id,
		title,
		link,
		ACFDentist: {
			headshot
		}
	} = dentist;

  	return (
        <Link key={id} to={link} className={styleSheet.dentist}>
			{ headshot ? <GatsbyImage
                image={headshot.localFile.childImageSharp.gatsbyImageData}
                className={styleSheet.image}
				placeholder="none"
                alt={title}
                loading={"eager"} /> : <div className={styleSheet.noimage}></div> }
			<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: title}}></span>
			<span className={`${styleSheet.view} ${passedClass}`}>View profile { icon && <FixedImage image={icon} passedClass={styleSheet.arrow} /> }</span>
		</Link>
    );
}

export default Dentist;
